<!-- 平台清分-查看详情 -->
<template>
  <div>
    <back />
    <div class="detail" v-loading="loading">
      <h3 class="title">订单信息</h3>
      <div>
        <el-row class="row">
          <el-col :span="8">
            <div class="col"><span class="txt">订单编号:</span>{{ detail?.orderNum }}</div>
            <div class="col"><span class="txt">结算类型:</span>{{ detail?.orderType == '1' ? '销售' : '退货' }}</div>
            <div class="col"><span class="txt">平台供货金额(元):</span>{{ detail?.supplyAmount }}</div>
            <div class="col"><span class="txt">平台结算金额(元):</span>{{ detail?.settlementAmount }}</div>
            <!-- <div class="col"><span class="txt">运费(元):</span>{{ detail?.freightAmount }}</div> -->
            <!-- <div class="col"><span class="txt">平台利润(元):</span>{{ detail?.XXX }}</div> -->
          </el-col>
          <el-col :span="8">
            <div class="col"><span class="txt">订单时间:</span>{{ detail?.orderTime }}</div>
            <div class="col"><span class="txt">支付类型:</span>{{ getOrderTypeText(detail?.payType) }}</div>
            <div class="col"><span class="txt">供应商供货金额(元):</span>{{ detail?.supplierSupplyAmount }}</div>

          </el-col>
          <el-col :span="8">
            <div class="col"><span class="txt">订单类型:</span>{{ detail?.orderSource == '1' ? '线下订单' : '线上订单' }}</div>
            <div class="col"><span class="txt">商超名称:</span>{{ detail?.shopName }}</div>
            <div class="col"><span class="txt">技术服务费(元):</span>{{ detail?.handlingFee }}</div>
            <!-- <div class="col"><span class="txt">销售结算金额(元):</span>{{ Number(detail?.supplyAmount) -
              Number(detail?.supplierSupplyAmount) }}</div> -->
          </el-col>
        </el-row>
      </div>

      <h3 class="title">商品信息</h3>
      <!-- 表格 -->
      <div class="globle_table">
        <el-table :data="tableData" style="width: 100%" height="500">
          <el-table-column label="序号" width="80" show-overflow-tooltip>
            <template slot-scope="scope">
              {{ scope.$index + 1 + (currentPage - 1) * pageSize }}
            </template>
          </el-table-column>
          <el-table-column min-width="120" show-overflow-tooltip prop="supplierName" label="供应商名称"></el-table-column>
          <el-table-column min-width="120" show-overflow-tooltip prop="barCode" label="商品条形码"></el-table-column>
          <el-table-column min-width="120" show-overflow-tooltip prop="productName" label="商品名称"></el-table-column>
          <el-table-column min-width="100" show-overflow-tooltip prop="specs" label="规格"></el-table-column>
          <el-table-column min-width="120" show-overflow-tooltip prop="categoryName" label="商品类目"></el-table-column>
          <el-table-column min-width="120" show-overflow-tooltip prop="brand" label="品牌"></el-table-column>
          <el-table-column min-width="150" show-overflow-tooltip prop="supplierSupplyAmount"
            label="供应商供货价(元)"></el-table-column>
          <el-table-column min-width="150" show-overflow-tooltip prop="handlingFeerate"
            label="技术服务费率(%)"></el-table-column>
          <el-table-column min-width="150" show-overflow-tooltip prop="handlingFee" label="技术服务费(元)"></el-table-column>
          <el-table-column min-width="150" show-overflow-tooltip prop="supplyPrice" label="平台供货价(元)"></el-table-column>
          <!-- 购买数量 -->
          <el-table-column min-width="120" show-overflow-tooltip prop="productCount" label="购买数量"
            v-if="detail?.orderType == 1"></el-table-column>
          <el-table-column min-width="120" show-overflow-tooltip prop="productCount" label="退货数量"
            v-if="detail?.orderType == 2"></el-table-column>

          <el-table-column min-width="140" show-overflow-tooltip prop="settlementAmount"
            label="平台结算金额(元)"></el-table-column>
          <!-- <el-table-column min-width="140" show-overflow-tooltip prop="" label="平台利润(元)"></el-table-column> -->
        </el-table>
      </div>


    </div>

    <!-- 分页 -->
    <!-- <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
      :current-page.sync="currentPage" :page-size="pageSize" :page-sizes="pageSizeArr"
      layout="prev, pager, next, jumper, sizes, total" :total="totalItemsCount">
    </el-pagination> -->

  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      detail: {},
      id: '',
      // 表格数据
      tableData: [],
      // 分页
      currentPage: 1, // 当前页码
      pageSize: 10, // 每页显示的行数
      pageSizeArr: [10, 20, 50], // 每页显示的行数(可选)
      totalItemsCount: 0, // 总记录数（需要从后端获取）
    }
  },
  created() {
    this.id = this.$route.query.id;
    this.getDetails();
  },
  methods: {
    getDetails() {
      this.loading = true;
      this.$axios.get(this.$api.getSettlementInfoDetail, {
        params: {
          id: this.id
        }
      }).then((res) => {
        if (res.data.code == 100) {
          this.detail = res.data.result;
          this.tableData = res.data.result.settlementDetailVoArrayList;
        }
        this.loading = false;
      }).catch(() => {
        this.loading = false;
      })
    },
    // 搜索
    onSearch() {
      this.clearData();
      this.getDetails();
    },
    // 切换每页显示的条数
    handleSizeChange(e) {
      this.pageSize = e;
      this.onSearch();
    },
    // 换页
    handleCurrentChange(e) {
      this.tableData = [];
      this.currentPage = e;
      this.getDetails();
    },
    getStatusText(status) {
      switch (status) {
        case '0':
          return "待审核";
        case '1':
          return "已转账";
        case '2':
          return "审核失败";
        case '3':
          return "转账失败";
        default:
          return " ";
      }
    },
    // 支付类型
    getOrderTypeText(status) {
      switch (String(status)) {
        case '0':
          return '现金支付';
        case '1':
          return '线上支付';
        case '3':
          return '会员卡支付';
        default:
          return '';
      }
    },
  }

}
</script>

<style lang="scss" scoped>
.detail {
  padding: 20px;

  .title {
    margin-bottom: 25px;
  }

  .row {
    color: #707070;
  }

  .col {
    margin-bottom: 25px;
  }

  .red {
    color: red;
  }

  .txt {
    margin-right: 8px;
  }

}
</style>
